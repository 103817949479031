a {
    text-decoration: none;
    color: #2196f3;
}

table {
    margin-bottom: 50px;
    border: 0;
    border-spacing: 0;
    white-space: nowrap;
    width: 100%;
}

th {
    text-align: left;
    height: 10px;
    padding: 20px 50px 20px 0px;
    border-bottom: 1px solid rgb(223, 223, 223);
    color: grey;
    font-weight: 400;
    font-size: 14px;
}

td {
    height: 10px;
    padding: 20px 50px 20px 0px;
    color: grey;
}

tr:hover {
    background-color: rgb(235, 235, 235);
    cursor: pointer;
}

.coming-soon {
    padding: 5em;
    color: grey;
}

.activity-holder {
    padding: 0px 50px;
    width: 100%;
}

.small-title {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0px;
}

.title-description {
    color: grey;
    margin-bottom: 25px;
}

.table-holder {
    max-height: 300px;
    overflow: auto;
}

.modal {
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: white;
    padding: 20px;
    box-shadow: '0px 0px 10px rgba(0,0,0,0.5)';
    max-height: 650px;
    width: 600px;
    overflow: auto;
}

.session-modal-title {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}

.close-session-modal-button {
    position: absolute;
    top: 7px;
    right: 7px;
    background-color: transparent;
    font-size: 18px;
    cursor: pointer;
}

.date-time {
    margin-bottom: 15px;
}

.overflow-auto {
    overflow: auto;
    max-height: 150px;
}

.shown-card {
    padding: 5px 15px;
    background-color: #e7e7e7;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    margin: 5px;
}

.shown-card:hover {
    opacity: 0.5;
    cursor: pointer;
}

.metric-card {
    padding: 5px 15px !important;
    background-color: #e7e7e7;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    margin-top: 25px;
}

.cards-shown-container {
    display: flex;
}

.keyword {
    background-color: rgba(255, 255, 0, 0.441);
    cursor: pointer;
}

.highlight {
    background-color: #67b0ec;
    color: white;
}