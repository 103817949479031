.component-container {
    padding: 25px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    margin: 25px 0px;
}

.slider-container {
    width: 100%;
    display: flex;
    padding: 0px 50px;
    border-bottom: 1px solid #e9e9e9;
}

.slider-item {
    padding: 10px 0px;
    margin-right: 35px;
    cursor: pointer;
}

.slider-item:hover {
    opacity: 0.5;
}

.active-slider-item {
    color: #2196f3;
    border-bottom: 2px solid #2196f3;
}

.teams-modal-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1900px;
    height: 100%;
    align-items: flex-start;
    align-content: flex-start;
    background-color: white;
    border-radius: 5px;
}

.teams-page-section {
    width: 100%;
}

.teams-content-holder {
    
}

.metrics-component-container {
    margin: 25px 0px;
    width: 100%;
}

.main-metrics-container {
    width: 100%;
    display: flex;
}

.main-metric {
    height: 100px;
    width: 25%;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 25px;
    margin-right: 25px;
}

.main-metric-title {
    font-weight: bold;
}

.main-metric-value {
    font-size: 22px;
    font-weight: bold;
    padding: 25px 0px;
}

.no-margin {
    margin: 0 !important;
}

.create-team-btn {
    width: 200px;
    height: 2em;
    background-color: #2196F3;
    color: white;
    border-radius: 0.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3em;
    cursor: pointer;
    margin: 50px;
}

.secondary-btn {
    color: #2196f3;
    cursor: pointer;
    font-size: 14px;
    padding: 0px 5px;
}

.secondary-btn:hover {
    opacity: 0.5;
}

.member-title-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.private-workspace-message {
    margin: 100px;
}

.date-picker-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 25px;
}

.date-picker {
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 10px 25px;
    width: 125px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-picker:hover {
    cursor: pointer;
    background-color: #e9e9e9;
    opacity: 0.5;
}

.card-review-container {
    display: flex;
}

.card-shown {
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 5px 15px !important;
    background-color: #e7e7e7;
    width: 300px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

.card-shown:hover {
    cursor: pointer;
    opacity: 0.5;
}

.card-container {
    margin-right: 150px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    height: 200px;
    flex-wrap: wrap;
}