.card-description {
  width: 85%;
  background: transparent;
  /* height: 300px; */
  height: calc(100% - 200px);
  /* border: 1px solid rgba(128, 128, 128, 0.506); */
  /* border-radius: 5px; */
  margin-top: 25px;
}

.open-card-description {
  height: 90% !important;
  width: 100%;
  background: transparent;
  height: 220px;
  border: none;
  margin-top: 25px;
}

.modal-title {
  position: absolute;
  top: 15px;
  color: #cccccc;
  font-size: 14px;
}
