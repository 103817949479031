.group-page-section {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  background: white;
}

.create-group-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  background-color: #2C2C2C;
  padding: 0.8em;
  margin: 15px;
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 1px 13px 1px #eaeaea;
}

.right-holder {
  width: 100%;
}

.create-group-btn {
  color: #cccccc;
  cursor: pointer;
  font-size: 18px;
  padding: 0px 5px;
  margin-bottom: 10px;
}

.group-text {
  font-size: 14px;
  font-weight: bold;
  padding: 0.4em;
  margin-bottom: 10px;
  color: #cccccc;
}


.create-card-btn {
  color: #2196f3;
  margin-top: 1em;
  cursor: pointer;
  font-size: 14px;
  padding: 0px 5px;
}

.card-holder {
  max-height: calc( 100% - 300px);
  overflow: auto;
}

.groups-dropdown {
  width: 100%;
  padding: 0.4em;
  background: #383838;
  border-radius: 4px;
  height: 40px;
  font-size: 14px;
  color: #cccccc;
}

.group-dropdown-wrapper {
  /* width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000030;
  z-index: 5; */
}

.scenario-modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 80vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}

.group-options-dropdown {
  width: 300px;
  height: 100px;
  position: absolute;
  top: -110px;
  left: 0px;
  border-radius: 5px;
  background-color: #383838;
  box-shadow: 0px 5px 5px 2px #1e1e1e66;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.group-btn {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.delete-group-text {
  width: 80%;
  margin-top: 15px;
  /* color: #e45858; */
  font-size: 16px;
  text-align: center;
}

.warning-icon {
  font-size: 28px;
  color: #e45858;
}

.delete-group-btn-modal {
  color: #e45858;
  cursor: pointer;
  padding: 15px;
  position: absolute;
  bottom: 15px;
}

.group-btn:hover {
  cursor: pointer;
  opacity: 0.5;
}

.group-btn-icon {
  margin: 0px 10px;
}

.dropdown-btn {
  margin: 0px 15px;
  font-size: 16px;
}

.dropdown-btn:hover {
  cursor: pointer;
  opacity: 0.5;
}

.session-btn-holder {
  position: absolute;
  bottom: 15px;
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
}

.start-session-btn {
  width: 100%;
  height: 2em;
  background-color: #cccccc;
  color: black;
  border-radius: 0.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3em;
  cursor: pointer;
}

.stop-session {
  background-color: #e45858;
}

.live-transcription-container {
  height: 3em;
  background: #383838;
  border-radius: 0.5em;
  max-height: 5em;
  overflow-y: auto;
  padding: 0.8em;
  position: relative;
  font-size: 13px;
  color: gray;
}

.live-transcription-container::-webkit-scrollbar {
  display: none;
}

.listening {
  font-size: 12px;
  color: #e45858;
  position: absolute;
  top: 3px;
  right: 5px;
}

.listening-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card {
  width: 280px;
  overflow: hidden;
  height: 25px;
  border-radius: 3px;
  color: rgb(204, 204, 204);
  background-color: #383838;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 14px;
}

.card:hover {
  opacity: 0.3;
}

.transcription-text {
  font-size: 13px;
  color: grey;
}

.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-text {
  margin-left: 10px;
}

.group-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.group-horizontal-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-container {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 351px);
  height: 100%;
  align-items: flex-start;
  overflow: auto;
  align-content: flex-start;
}

.mic-settings-btn {
  height: 15px;
  width: 15px;
  border-radius: 25px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
  color: grey;
}

.mic-settings-btn:hover {
  opacity: 0.3;
}

.not-available {
  opacity: 0.5;
}

.selected-group-text {
  position: absolute;
  bottom: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #cccccc;
}

.delete-btn {
  padding-left: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #e45858;
}

.delete-btn:hover {
  opacity: 0.3;
}

.ql-container {
  border: none !important;
  padding: 5px;
  border-radius: 5px;
  height: 80%;
  /* height: 250px; */
  overflow: auto;
  color: #cccccc;
}

.ql-disabled {
  border: none !important;
  padding: 0;
}

.ql-editor {
  padding: 0;
  padding-top: 10px;
}

.ql-editor::-webkit-scrollbar { 
  display: none; 
}

.ql-toolbar {
  border: none !important;
  color: #cccccc !important;
}

.ql-bold {
  color: #cccccc !important;
}

.left-menu {
  width: 250px;
  min-width: 250px;
  border-right: 1px solid #e9e9e9;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
}

.workspace-text {
  font-size: 16px;
  font-weight: bold;
  width: 250px;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 15px 0px;
  color: rgb(39, 39, 39);
  cursor: pointer;
}

.workspace-text:hover {
  opacity: 0.5;
}

.vertical-icon {
  font-size: 16px;
  margin-left: 15px;
}

.menu-items-holder {
  margin-top: 20px;
}

.menu-item {
  display: flex;
  align-items: center;
  color: rgb(167, 167, 167);
  padding: 15px 25px;
  cursor: pointer;
  /* font-size: 16px; */
}

.menu-item:hover {
  opacity: 0.4;
}

.active {
  color: #1e1e1e;
}

.menu-text {
  margin-left: 10px;
}

.logout-btn {
  color: #e45858;
  position: absolute;
  bottom: 15px;
  cursor: pointer;
  padding: 15px 25px;
}

.top-bar {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  background-color: #F7F7F7;
}

.content-holder {
  height: calc( 100vh - 51px);
  display: flex;
}

.bg-dots {
  --dot-bg: #F7F7F7;
  --dot-color: grey;
  --dot-size: 1px;
  --dot-space: 22px;
	background:
		linear-gradient(90deg, var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
		linear-gradient(var(--dot-bg) calc(var(--dot-space) - var(--dot-size)), transparent 1%) center / var(--dot-space) var(--dot-space),
		var(--dot-color);
}

.user-holder {
  width: 220px;
  display: flex;
  height: 50px;
  align-items: center;
  margin: 0 auto;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  background: white;
}

.user-img {
  font-size: 28px;
  color: #8edd91;
  padding: 0px 15px;
  margin-top: 4px;
}

.user-name {
  padding-right: 25px;
  color: rgb(25, 25, 25);
}

.user-name-role {
  color: grey;
  font-size: 13px;
}

.search {
  width: calc( 100% - 350px);
  display: flex;
  align-items: center;
}

.search-bar {
  width: 400px;
  height: 35px;
  border-radius: 25px;
  background-color: #eaeaeab8;
  margin: 0px 50px;
  display: flex;
  align-items: center;
}

.search-icon {
  font-size: 22px;
  margin-left: 15px;
  margin-top: 2px;
  color: grey;
}

.notification-bar {
  width: 350px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: white;
  color: grey;
  position: absolute;
  left: calc( 50% - 175px);
  top: 5px;
  box-shadow: 0px 3px 12px 4px #00000030;
  border: 1px solid rgb(214, 214, 214);
}

.notification-info {
  padding: 15px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-container {
  position: fixed;
  bottom: 10px;
  left: 260px;
  background-color: lightyellow;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 8px;
  color: #4c4c4c;
}

.refresh-btn {
  color: #2196f3;
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
}

.temp-close-notification {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
}

.close-notification {
  height: 20px;
  width: 100%;
  cursor: pointer;
  background-color: #e3e3e3;
  padding: 5px 0px;
  text-align: center;
  vertical-align: middle;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
}

.close-notification:hover {
  opacity: 0.5;
}

.alert-icon {
  margin-right: 15px;
  font-size: 60px !important;
}

.google-profile-img {
  border-radius: 25px;
  width: 30px;
}

.interim-results {
  color: rgb(199, 199, 199);
}

.free-trial-text {
  color: grey;
  margin-left: 25px;
}

.free-trial-upgrade {
  color: #2196F3;
  margin-left: 15px;
  cursor: pointer;
}

.script-container {
  width: 250px;
  height: 500px;
  border-radius: 15px;
  background-color: white;
  margin: 32px;
  color: rgb(40, 40, 40);
  padding: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  /* border: 1px solid rgb(233, 233, 233); */
  box-shadow: 0px 1px 13px 1px #eaeaea;
}

.script-title {
  width: 100%;
  text-align: center;
  color: rgb(45, 45, 45);
  padding: 0px 0px 15px 0px;
  font-size: 18px;
}

.checkmark {
  margin-left: 10px;
  color: grey;
}

.container {
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 4px;
}

.container:hover {
  cursor: pointer;
  background-color: #d3d3d3;
}

.script-minimize {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.script-maximize {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
}

.script-maximize:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}

.script-minimize:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}