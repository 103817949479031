.create-team-modal {
    width: 350px;
    height: 150px;
    background: white;
    display: flex;
    flex-direction: column;
    border-radius: 0.5em;
    align-items: center;
    margin: 2em;
    position: absolute;
    top: calc( 30% );
    left: calc( 50% - 175px - 2em);
    padding-top: 50px;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 5px 5px rgba(128, 128, 128, 0.407);
    z-index: 2;
}

.team-modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.319);
    z-index: 1;
}