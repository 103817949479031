.app-container {
  height: 100vh;
  display: flex;
}

body {
  font-family: 'Roboto', sans-serif;
}

.main-content {
  width: calc( 100% - 251px);
  overflow: auto;
}

.home-width {
  width: 100% !important;
}