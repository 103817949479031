.create-group-modal {
  width: 350px;
  height: 150px;
  background: #2c2c2c;
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  align-items: center;
  margin: 2em;
  position: relative;
  padding-top: 50px;
  border: 1px solid #e9e9e9;
}

.create-card-modal {
  width: 500px;
  height: 540px;
  min-width: 500px;
  min-height: 540px;
  margin: 15px;
  background: #2C2C2C;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  align-items: center;
  position: relative;
  padding-top: 50px;
  /* border: 1px solid #e9e9e9; */
  resize: auto;
  overflow: auto;
  box-shadow: 0px 1px 13px 1px #eaeaea;
}

.create-group-btn-modal {
  color: #2182fe;
  cursor: pointer;
  padding: 15px;
  position: absolute;
  bottom: 15px;
}

.group-name-input {
  width: 80%;
  border: 1px solid rgba(128, 128, 128, 0.506);
  padding: 0.75em 0.5em;
  margin-top: 25px;
  background: transparent;
  border-radius: 3px;
  color: #cccccc;
}

.close-button-holder {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  color: #cccccc;
}

.close-button-holder:hover {
  opacity: 0.4;
}
