.card-name-input {
    width: 80%;
    border: none;
    padding: 0.5em;
    margin-top: 25px;
    background: transparent;
    border-radius: 0.4em;
}

.open-card-modal {
    width: 400px;
    height: 415px;
    background: #2c2c2c;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    margin: 1em;
    position: relative;
    padding: 25px;
    /* border: 1px solid #e9e9e9; */
    resize: both;
    overflow: auto;
    box-shadow: 0px 1px 13px 1px #eaeaea;
}

.open-modal-title {
    font-size: 14px;
    color: #cccccc;
}