.teams-dropdown {
    padding: 5px 0px;
    margin-right: 5px;
    font-size: 16px;
    font-weight: bold;
    max-width: 100%;
    background-color: transparent;
}

.teams-dropdown:focus-visible {
    border: none;
    outline: 0;
}

.minimize-left-menu {
    color: grey;
    position: absolute;
    bottom: 5px;
}